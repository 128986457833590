<template>
    <div class="row justify-content-center h-100-vh">
        <div class="col-12 col-xl-11" v-show="!productError">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="storeInformation.currency == null && newProduct && !loading.storeData">
                        <div
                            class="card-body text-center animated fadeIn h-50-vh d-flex flex-column align-items-center justify-content-center">
                            <h3> <strong v-text="$t('messages.updateStoreCurrency')"> </strong> </h3>
                            <p>
                                <strong>ID: </strong> <span v-text="storeInformation.id"></span> <br>
                                <router-link :to="'/dashboard/stores/' + storeInformation.id ">
                                    <strong v-text="$t('general.button.here')"></strong>
                                </router-link>
                            </p>
                        </div>
                    </div>
                    <no-data isLoading v-if="loading.product"></no-data>
                    <div class="row" v-show=" storeInformation.currency != null && !loading.product">
                        <div class="col-12 col-lg-7 col-xl-8 order-2 order-lg-1 ">
                            <ProductForm />
                        </div>
                        <div class="col-12 col-lg-5 col-xl-4 order-1 order-lg-2 ">
                            <div class="card" v-if="!newProduct"
                                :class=" productInformation.active ? '' : 'text-muted' ">
                                <div class="card-body">
                                    <div class="row align-items-center mb-2">
                                        <div class="col">
                                            <h5 class="m-0">
                                                <small>
                                                    <strong
                                                        :class=" productInformation.active ?  'text-success' : 'text-muted' "
                                                        v-text=" (productInformation.active ? $t('general.online'): $t('general.offline') )  ">
                                                    </strong>
                                                </small>
                                            </h5>
                                        </div>
                                        <div class="col-auto">
                                            <div class="media-body switch mx-auto">
                                                <label class="switch">
                                                    <input type="checkbox" v-model="productInformation.active"><span
                                                        class="switch-state "
                                                        :class=" productInformation.active ? 'bg-success': '' "
                                                        @click=" fnApiChangeStatusProduct() "></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <h6 class="text-right mb-2">
                                        <strong v-if="getProductDiscount"
                                            v-text=" fnFormatPrice( (getProductDiscount.is_amount) ? (productInformation.price - getProductDiscount.percent) : (productInformation.price - ( (productInformation.price /100) * getProductDiscount.percent)) , productInformation.currency ) ">
                                        </strong>
                                        <strong
                                            v-text="fnFormatPrice( productInformation.price , productInformation.currency ) "
                                            v-else></strong>
                                        <span class="text-right ml-1" v-if="getProductDiscount">
                                            <small>
                                                <strong
                                                    :class=" productInformation.active ?  'text-success' : 'text-muted' "
                                                    v-text="((getProductDiscount.is_amount) ? fnFormatPrice(getProductDiscount.percent, productInformation.currency ) : (getProductDiscount.percent + '%' ) )+ ' OFF' "></strong>
                                            </small>
                                        </span>
                                    </h6>
                                    <hr>
                                    <div class="row justify-content-end">
                                        <div class="col-auto">
                                            <a class="btn btn-link btn-sm text-success"
                                                :href="CONST.ECART.PRODUCT + productInformation.slug" target="_blank">
                                                <span v-text="$t('tables.viewInStore')"></span>
                                                <i class="fa fa-external-link-alt ml-2"></i>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <p>
                                                <span class="f-w-700 text-muted"
                                                    v-text="$t('general.store') + ': ' "></span>
                                                <span class="f-w-600" v-text="storeInformation.name"></span> <br>
                                            </p>
                                            <p>
                                                <span class="text-muted f-w-700"
                                                    v-text="$t('general.language') + ': ' "></span>
                                                <span class="text-uppercase f-w-600"
                                                    v-text="storeInformation.settings.language"></span>
                                                <i class=" fa fa-question-circle text-info ml-1"
                                                    id="popover-language"></i>
                                                <b-popover placement="topright" target="popover-language"
                                                    triggers="hover">
                                                    <p v-html="$t('messages.languageProductMessage')"></p>
                                                </b-popover>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!newProduct">
                                <ProductImprovements />
                                <ProductTranslations />
                                <GetProductDiscounts />
                                <ProductStats />
                                <ProductSync />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductQuestions />
            <ProductReviews />
        </div>
        <div class="col-12">
            <div class="card " v-show="productError">
                <div
                    class="card-body text-center h-100-vh d-flex align-items-center flex-column justify-content-center ">
                    <p class="f-24">
                        <strong v-text=" $t('noDataMessages.errorDefault') "></strong>
                    </p>
                    <p>
                        <span v-text="$t('tables.product') + ': ' "> </span>
                        <strong v-text="this.$route.params.id"></strong>
                    </p>
                    <router-link to="/dashboard/products">
                        <strong
                            v-text="$t('dashboard.welcome.goTo') + ' ' + $t('dashboard.menu.products').toLowerCase() "></strong>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState
    } from 'vuex';
    import ProductForm from './components/product-form.vue'

    import GetProductDiscounts from './components/product-discounts.vue'
    import ProductTranslations from './components/product-translations.vue'
    import ProductImprovements from './components/product-improvements.vue'
    import ProductSync from './components/product-sync.vue'
    import ProductStats from './components/product-stats.vue'

    import ProductQuestions from './components/product-questions-modal';
    import ProductReviews from './components/product-reviews-modal';

    export default {
        components: {
            ProductForm,
            GetProductDiscounts,
            ProductTranslations,
            ProductImprovements,
            ProductReviews,
            ProductQuestions,
            ProductSync,
            ProductStats,
        },

        computed: {
            ...mapState('EcartProduct', ['newProduct', 'loading', 'productError', 'productLanguage', 'modals',
                'productInformation', 'storeInformation'
            ]),
            ...mapGetters('EcartProduct', ['getProductDiscount']),
            ...mapState('productReviews', ['reviewsPagination']),
            ...mapState('productQuestions', ['questionsPagination']),
            linkToStore() {
                return process.env.VUE_APP_ECART_STORE + 's/store/' + this.storeInformation.id;
            },

        },
        watch: {
            async $route(to, from) {
                if (this.$route.params.id != this.productInfo.id) {
                    this.$forceUpdate();
                    this.changeLoading({
                        data: true
                    });
                    await this.fnApiGetProduct(this.$route.params.id);
                    this.changeLoading({
                        data: false
                    });
                }
                this.$route.query.questions ? this.changeModal({
                    questions: true
                }) : this.changeModal({
                    questions: false
                });
                this.$route.query.reviews ? this.changeModal({
                    reviews: true
                }) : this.changeModal({
                    reviews: false
                });
            },

            productInformation() {
                if (this.productInformation.id != null) {
                    this.reviewsPagination.productId = this.productInformation.id;
                    this.questionsPagination.productId = this.productInformation.id;
                    this.fnApiGetCountReviews();
                    this.fnApiGetCountQuestions();
                };
            },

        },
        methods: {
            ...mapActions('EcartProduct', ['fnApiGetProduct', 'fnApiGetStore', 'fnApiChangeStatusProduct']),
            ...mapMutations('EcartProduct', ['changeModal', 'changeLoading', 'resetState', 'setNewProduct']),
            ...mapActions('productReviews', ['fnApiGetCountReviews']),
            ...mapActions('productQuestions', ['fnApiGetCountQuestions']),
        },
        beforeMount() {
            this.changeLoading({
                data: true
            })
            if (this.$route.params.storeId) {
                this.setNewProduct(true);
                this.fnApiGetStore(this.$route.params.storeId);
            }
        },
        async mounted() {
            if (this.$route.params.id) {
                await this.fnApiGetProduct(this.$route.params.id);
            }
            this.changeLoading({
                data: false
            })
        },
        beforeDestroy() {
            this.resetState();
        }
    }
</script>