<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col">
                    <h6 class="m-0" v-text="$t('general.statistics')"></h6>
                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-success circle-25" @click="showStats = !showStats">
                        <i class="fa fa-eye fa-sm" v-if="!showStats"></i>
                        <i class="fa fa-eye-slash fa-sm" v-else></i>
                    </button>
                </div>
            </div>

            <div class="animated fadeIn" v-show="showStats">
                <table class="table table-striped table-bordered mt-2">
                    <tbody>
                        <tr>
                            <td class="col" v-text="$t('tables.sales')"> </td>
                            <td class="text-center"
                                v-text=" productInformation.sales ? productInformation.sales : '0' "></td>
                        </tr>
                        <tr>
                            <td v-text="$t('tables.views')"> </td>
                            <td class="text-center" v-text=" productInformation.views ? productInformation.views : '0' "></td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <span v-text="$t('dashboard.product.questions') + ' - ' "> </span>
                                    <button class="btn btn-link btn-xs p-0" @click="changeModal( {questions:true} )">
                                        <span v-text="$t('general.button.view')"></span>
                                    </button>
                                </p>
                            </td>
                            <td class="text-center">
                                <p>
                                    <span>
                                        <span v-text=" questionsPagination.total "
                                            v-if=" questionsPagination.total != null "></span>
                                        <i class="fa fa-spinner fa-spin " v-else></i>
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    <span v-text="$t('dashboard.product.reviews') + ' - ' "> </span>
                                    <button class="btn btn-link btn-xs p-0" @click="changeModal( {reviews:true} )">
                                        <span v-text="$t('general.button.view')"></span>
                                    </button>
                                </p>
                            </td>
                            <td class="text-center">
                                <p>
                                    <span>
                                        <span v-text=" reviewsPagination.total "
                                            v-if=" reviewsPagination.total != null "></span>
                                        <i class="fa fa-spinner fa-spin " v-else></i>
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {
                showStats: false,
            }
        },
        computed: {
            ...mapState('EcartProduct', ['productInformation']),
            ...mapState('productReviews', ['reviewsPagination']),
            ...mapState('productQuestions', ['questionsPagination']),
        },
        methods: {
            ...mapActions('EcartProduct', ['fnApiSyncProduct']),
            ...mapMutations('EcartProduct', ['changeModal']),
        }
    }
</script>